import { BedIcon } from "../assets/svg/BedIcon";
import { ChairIcon } from "../assets/svg/ChairIcon";
import { DecorIcon } from "../assets/svg/DecorIcon";
import { LightIcon } from "../assets/svg/LightIcon";
import { PlantIcon } from "../assets/svg/PlantIcon";
import { RoomIcon } from "../assets/svg/RoomIcon";
import { StorageIcon } from "../assets/svg/StorageIcon";
import { TableIcon } from "../assets/svg/TableIcon";
import { SofaIcon } from "./../assets/svg/SofaIcon";
import {
  categoryDataT,
  dataBed,
  dataChair,
  dataDecor,
  dataLight,
  dataPlant,
  dataRoom,
  dataSofa,
  dataStorage,
  dataTable,
} from "./categoriesData";
export const TYPE_REDUCER = {
  SET_MODAL: "set modal status",
  SET_ACTIVE_TAB: "set active tab",
  SET_SELECTED_MODEL: "selected Model",
  SET_WEBSOCKET_CONNECTED: "set Ws Connected",
  SET_RENDER_HASH: "set Render Hash",
  SET_PRODUCTS: "set products",
  SET_CATEGORIES: "set categories",
  SET_SERVER_STATUS: "set server status"
};
export const apiAssets = "https://scooterapi.3dconfiguration.com/files/test/";

export type categoryId =
  | "sofa"
  | "bed"
  | "storage"
  | "table"
  | "chair"
  | "light"
  | "plant"
  | "decor"
  | "room";

export type filterT = "Black color" | "White color";
export type categoryesListT = {
  type: "environment" | "interior";
  id: categoryId;
  icon?: any;
  img?: any;
  label: string;
  data: categoryDataT[];
  search?: boolean;
  filter?: filterT[];
  sort?: boolean;
};

export type cameraListT = {
  value: any;
  label: string;
  id: number;
};
export const cameraList: cameraListT[] = [
  {
    value: {
      pos: { x: 2.69645, y: 2.32062, z: 1.32439 },
      rot: { x: -23.69999999999998, y: 60.35, z: -1.449999999999993 },
    },
    label: "Camera Preset 1",
    id: 0,
  },
  {
    value: {
      pos: {
        x: -1.1763838643261164,
        y: 2.8664114380677757,
        z: -1.0825253911422594,
      },
      rot: {
        x: -89.99277552207256,
        y: 72.63801374383216,
        z: 1.0658423086412726e-14,
      },
    },
    label: "Camera Preset 2",
    id: 1,
  },
  {
    value: {
      pos: {
        x: -4.335934489861157,
        y: 1.8792639102980124,
        z: -5.102128756538293,
      },
      rot: { x: 167.76166826153653, y: -52.35421555388755, z: 180 },
    },
    label: "Camera Preset 3",
    id: 2,
  },
];
export const categoryesList: categoryesListT[] = [
  {
    type: "environment",
    id: "room",
    icon: RoomIcon,
    label: "Rooms",
    data: dataRoom,
  },
  // {
  //   type: "interior",
  //   id: "sofa",
  //   icon: SofaIcon,
  //   label: "Sofas",
  //   data: dataSofa,
  //   search: true,
  //   sort: true,
  //   filter: ["Black color", "White color"],
  // },
  // {
  //   type: "interior",
  //   id: "bed",
  //   icon: BedIcon,
  //   label: "Beds",
  //   data: dataBed,
  //   search: true,
  //   sort: true,
  //   filter: ["Black color", "White color"],
  // },
  // {
  //   type: "interior",
  //   id: "storage",
  //   icon: StorageIcon,
  //   label: "Storage",
  //   data: dataStorage,
  //   search: true,
  //   sort: true,
  //   filter: ["Black color", "White color"],
  // },
  // {
  //   type: "interior",
  //   id: "table",
  //   icon: TableIcon,
  //   label: "Tables",
  //   data: dataTable,
  //   search: true,
  //   sort: true,
  //   filter: ["Black color", "White color"],
  // },
  // {
  //   type: "interior",
  //   id: "chair",
  //   icon: ChairIcon,
  //   label: "Chairs",
  //   data: dataChair,
  //   search: true,
  //   sort: true,
  //   filter: ["Black color", "White color"],
  // },
  // {
  //   type: "interior",
  //   id: "light",
  //   icon: LightIcon,
  //   label: "Lights",
  //   data: dataLight,
  //   search: true,
  //   sort: true,
  //   filter: ["Black color", "White color"],
  // },
  // {
  //   type: "interior",
  //   id: "plant",
  //   icon: PlantIcon,
  //   label: "Plants",
  //   data: dataPlant,
  //   search: true,
  //   sort: true,
  //   filter: ["Black color", "White color"],
  // },
  // {
  //   type: "interior",
  //   id: "decor",
  //   icon: DecorIcon,
  //   label: "Decor",
  //   data: dataDecor,
  //   search: true,
  //   sort: true,
  //   filter: ["Black color", "White color"],
  // },
];
