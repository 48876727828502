import { FC, useEffect, useRef } from "react";
import s from "./ModalServerOffline.module.scss";
import { useDispatch } from "react-redux";
import { handleImage } from "../../api/renderAndLoadImage";
import { Close } from "../../../assets/svg/Close";
type ModalServerOfflineT = {
  closeFunc: any;
};
export const ModalServerOffline: FC<ModalServerOfflineT> = ({ ...props }) => {
  const { closeFunc } = props;
  const wrapperRef: any = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleClickOutside =
      (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
    document.addEventListener(
      "mousedown",
      handleClickOutside(wrapperRef, closeFunc)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );
    };
  }, [wrapperRef]);
  //@ts-ignore

  return (
    <div className={s.wrapper} ref={wrapperRef}>
      <div className={s.head}>
        <span></span>
        <div className={s.close} onClick={closeFunc}>
          <Close />
        </div>
      </div>
      <div className={s.title}>Oops!</div>
      <div className={s.subtitle}>
        The server is currently offline, so we can't show the image right now.
        Please try again later.
      </div>
      <div className={s.reload} onClick={() => window.location.reload()}>
        Reload page
      </div>
    </div>
  );
};