import { FC, useState, useEffect, useRef, useId } from "react";
import s from "./ModalSaveConfig.module.scss";
import { useWindowWidth } from "../../../utils/functions/useWindowWidth";
import { CopyIcon } from "../../../assets/svg/CopyIcon";
import { useDispatch } from "react-redux";
import { Close } from "../../../assets/svg/Close";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { popUp } from "../../../features/popUp/PopUp";
import { getConfigInfo } from "../../../utils/functions/playcanvas/getConfigInfo";
import sha256 from "crypto-js/sha256";
import hmacSHA512 from "crypto-js/hmac-sha512";
import Base64 from "crypto-js/enc-base64";
import api from "../../api";
async function shortenUrl(longUrl: any, shortName: any) {
  const apiUrl =
    "https://ulvis.net/api.php?url=https://publicapi.dev/free-url-shortener-apiL&custom=ffddd&private=1";
  // const apiUrl = `https://ulvis.net/api.php?url=${encodeURIComponent(
  //   longUrl
  // )}&custom=${shortName}`;

  try {
    const response = await fetch(apiUrl);
    if (response.ok) {
      const data = await response.json();
      console.log("data: ", data);
      if (data && data.url) {
        return data.url;
      }
    }
    throw new Error("Failed to shorten URL");
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
function isValidEmail(email: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
function truncateString(inputString: string, maxLength: number) {
  if (inputString.length <= maxLength) {
    return inputString; // Возвращаем исходную строку, если она короче или равна заданной длине.
  } else {
    return inputString.slice(0, maxLength) + "..."; // Обрезаем строку до заданной длины и добавляем тройные точки.
  }
}
type ModalSaveConfigT = {
  closeFunc: any;
};
export const ModalSaveConfig: FC<ModalSaveConfigT> = ({ ...props }) => {
  const { closeFunc } = props;
  const [href, setHref] = useState(
    `${window.location.origin}/?config=9c96bb1bafc99019a59403a29b3fec76420956f5d5e97179a86d69f9d1b5bbf1`
  );
  // const [value, setValue] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  // const [error, setError] = useState(false);
  const wrapperRef: any = useRef(null);
  // const mailId = useId();
  const dispatch = useDispatch();
  useEffect(() => {
    async function setConfigCode() {
      const info = await getConfigInfo();
      const hash = await api.genShortUrl.genShortUrl(info);
      setHref(`${window.location.origin}/?config=${hash.configuration_hash}`);
    }
    setConfigCode();

    const handleClickOutside =
      (wrapperRef: any, closeFunc: any) => (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          closeFunc();
        }
      };
    document.addEventListener(
      "mousedown",
      handleClickOutside(wrapperRef, closeFunc)
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside(wrapperRef, closeFunc)
      );
    };
  }, [wrapperRef]);

  function onSubmitForm() {
    popUp({ type: "success", message: "Email sent successfully." });
    closeFunc();
    // let formData = new FormData();
    // formData.append("href", href);
    // axios
    //   .post("https://formspree.io/f/mjvqowpl", formData, {
    //     headers: {
    //       // "Content-Type": "multipart/form-data",
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then((response: any) => {
    //     console.log("respo123nse: ", response);
    //     if (response.data.ok) {
    //       closeFunc();
    //     }
    //   })
    //   .catch((error: any) => {
    //     console.log("error: ", error);
    //     popUp({ type: "failure", message: "Error sending email." });
    //   });
  }

  return (
    <div className={s.wrapper} ref={wrapperRef}>
      <div className={s.head}>
        <span>Save & Restore</span>
        <div className={s.close} onClick={closeFunc}>
          <Close />
        </div>
      </div>
      <div className={s.titleWrapper}>
        <div className={s.title}>Save Configuration</div>
        <div className={s.subtitle}>share your current configuration</div>
      </div>
      {/* <div className={s.mail}>
        <label htmlFor={mailId}>Enter email</label>
        <input
          type="text"
          id={mailId}
          name="email"
          value={value}
          onChange={(e: any) => {
            const isValid = isValidEmail(e.target.value);
            console.log("isValid: ", isValid);
            setError(!isValid);
            setValue(e.target.value);
          }}
          placeholder="example@gmail.com"
        />
        {error && <div className={s.error}>Invalid email address format.</div>}
      </div> */}
      <footer>
        <div className={s.text}>
          You can view all the items that you have selected by following the
          link below
        </div>

        <div className={s.copyWrapper}>
          <div className={s.copy}>{truncateString(href, 40)}</div>
          <CopyToClipboard text={href}>
            <div
              className={s.copyBtn}
              onClick={() => {
                // copy(value);
                setIsCopied(true);
                setTimeout(() => {
                  setIsCopied(false);
                }, 5000);
              }}
            >
              <CopyIcon />
              <span>{isCopied ? "copied" : "Copy link"}</span>
            </div>
          </CopyToClipboard>
        </div>
        <div className={s.nav}>
          <div className={s.cancel} onClick={closeFunc}>
            Cancel
          </div>
          {/* <div
            className={`${s.accept} ${!error && value !== "" && s.active}`}
            onClick={() => onSubmitForm()}
          >
            Confirm
          </div> */}
        </div>
      </footer>
    </div>
  );
};
