import { useDispatch, useSelector } from "react-redux";
import { DeleteIcon } from "../../assets/svg/DeleteIcon";
import { MoveIcon } from "../../assets/svg/MoveIcon";
import { RotateIcon } from "../../assets/svg/RotateIcon";
import {
  dimTypeT,
  playerSettings,
} from "../../utils/functions/playcanvas/playerSettings";
import s from "./PlayerSettings.module.scss";
import { FC, useEffect, useState } from "react";
import {
  setCategories,
  setProducts,
  setSelectedModel,
} from "../../redux/actions";
import { getSelectedModel } from "../../redux/selectors/selectors";
import { checkModelSelected } from "../../utils/functions/playcanvas/checkModelSelected";
import { SnapIcon } from "../../assets/svg/SnapIcon";
import api from "../../processes/api";
const defaultCoords = { x: 120, y: 20 };
export const PlayerSettings: FC = () => {
  const dispatch = useDispatch();
  const { selectedType, selectedModel } = useSelector(getSelectedModel());
  const [snapType, setStapType] = useState(false);
  const [sceneIsload, setSceneIsLoad] = useState(false);
  const [coords, setCoords] = useState(defaultCoords);
  useEffect(() => {
    dispatch(setSelectedModel({ selectedType: "move" }));
    checkModelSelected(dispatch, setCoords);
  }, []);
  function modelToggle(type: dimTypeT) {
    playerSettings({ type });
    if (type === "delete") {
      // dispatch(setSelectedModel({ selectedModel: false }));
    } else {
      dispatch(setSelectedModel({ selectedType: type }));
    }
  }
  useEffect(() => {
    //@ts-ignore
    const containerRef = window.containerRef;
    const pathname = window.location.pathname;
    const parts = pathname.split("/");
    if (containerRef) {
      const getProducstsInApi = async () => {
        const products = await api.productInfo.getProduct({
          idProject: parts[1],
        });
        dispatch(setProducts({ products }));
      };
      getProducstsInApi();
      const getCategoriesInApi = async () => {
        const { keys, data } = await api.productInfo.getCategories({
          idProject: parts[1],
        });
        //@ts-ignore
        window.categoriesData = data;
        dispatch(setCategories({ categories: keys }));
      };
      getProducstsInApi();
      getCategoriesInApi();
      //@ts-ignore
      window.containerRef = containerRef;
      containerRef.current.addEventListener("load", () => {
        setSceneIsLoad(true);
      });
    }
  }, []);

  useEffect(() => {
    if (!selectedModel && sceneIsload) {
      playerSettings({ type: "snapOff" });
      setStapType(false);
    }
  }, [selectedModel]);
  return (
    <>
      {selectedModel && (
        <div
          className={s.wrapper}
          style={{
            left: `calc(${coords.x}px - 90px)`,
            top: `calc(${coords.y}px + 50px)`,
          }}
        >
          <div
            className={`${s.move} ${s.item} ${
              selectedType === "move" && s.active
            }`}
            onClick={() => modelToggle("move")}
          >
            <MoveIcon />
            <span>Move</span>
          </div>
          <div className={s.line}></div>
          <div
            className={`${s.rotate} ${s.item} ${
              selectedType === "rotate" && s.active
            }`}
            onClick={() => modelToggle("rotate")}
          >
            <RotateIcon />
            <span>Rotate</span>
          </div>
          <div className={s.line}></div>
          <div
            className={`${s.snap} ${s.item} ${snapType && s.active}`}
            onClick={() => {
              playerSettings({ type: !snapType ? "snapOn" : "snapOff" });
              setStapType(!snapType);
            }}
          >
            <SnapIcon />
            <span>Snap</span>
          </div>
          <div className={`${s.delete}`} onClick={() => modelToggle("delete")}>
            <DeleteIcon />
            <span>Delete</span>
          </div>
        </div>
      )}
    </>
  );
};
