import axios, { AxiosError } from "axios";
import { apiData } from ".";
import { popUp } from "../../features/popUp/PopUp";
import { ApiData } from "./renderAndLoadImage";

export class getShortUrlApi extends ApiData {
  public async getShortUrl(configParam: string): Promise<any> {
    try {
      const { data } = await axios.get(`${this.baseURL}/getConfiguration/${configParam}`, {
        headers: { Authorization: `${this.token}` },
      });

      return data;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        popUp({ type: "failure", message: err.message });
        if (err.response) {
          return err.response.data;
        } else {
          return err.message;
        }
      }

      return "Error";
    }
  }
}
