import React from "react";
import { useDispatch, useSelector } from "react-redux";
import s from "./ModalFrame.module.scss";
import { ModalSaveConfig } from "../ModalSaveConfig/ModalSaveConfig";
import { ModalStatusT, NameModals } from "../../../redux/reducers/types";
import { changeShowModal } from "../../../redux/actions";
import { getStateModal } from "../../../redux/selectors/selectors";
import { Loader } from "../Loader/Loader";
import { RenderLoader } from "../RenderLoader/RenderLoader";
import { RenderPreview } from "../RenderPreview/RenderPreview";
import { ModalPleaseNote } from "../ModalPleaseNote/ModalPleaseNote";
import { ModalServerOffline } from "../ModalServerOffline/ModalServerOffline";
export const ModalFrame = () => {
  const dispatch = useDispatch();

  const closeFunc = (name: NameModals) => {
    dispatch(changeShowModal({ nameModal: name, stateModal: false }));
  };

  const tutorial: { name: NameModals; data: ModalStatusT } = useSelector(
    getStateModal("saveConfig")
  );

  const loader: { name: NameModals; data: ModalStatusT } = useSelector(
    getStateModal("loader")
  );

  const renderLoader: { name: NameModals; data: ModalStatusT } = useSelector(
    getStateModal("renderLoader")
  );

  const renderPreview: { name: NameModals; data: ModalStatusT } = useSelector(
    getStateModal("renderPreview")
  );
  const pleaseNote: { name: NameModals; data: ModalStatusT } = useSelector(
    getStateModal("pleaseNote")
  );
  const serverOffline: { name: NameModals; data: ModalStatusT } = useSelector(
    getStateModal("serverOffline")
  );
  
  return (
    <>
      {tutorial.data["isShow"] && (
        <div className={s.modal_bg} style={{ zIndex: 9999 }}>
          <ModalSaveConfig closeFunc={() => closeFunc(tutorial.name)} />
        </div>
      )}
      {loader.data["isShow"] && (
        <div className={s.modal_bg} style={{ zIndex: 9999 }}>
          <Loader />
        </div>
      )}
      {renderLoader.data["isShow"] && (
        <div className={s.modal_bg} style={{ zIndex: 9999 }}>
          <RenderLoader closeFunc={() => closeFunc(renderLoader.name)} />
        </div>
      )}
      {renderPreview.data["isShow"] && (
        <div className={s.modal_bg} style={{ zIndex: 9999 }}>
          <RenderPreview
            closeFunc={() => closeFunc(renderPreview.name)}
            img={renderPreview.data.otherParams.img}
          />
        </div>
      )}
      {pleaseNote.data["isShow"] && (
        <div className={s.modal_bg} style={{ zIndex: 9999 }}>
          <ModalPleaseNote closeFunc={() => closeFunc(pleaseNote.name)} />
        </div>
      )} 
      {serverOffline.data["isShow"] && (
        <div className={s.modal_bg} style={{ zIndex: 9999 }}>
          <ModalServerOffline closeFunc={() => closeFunc(serverOffline.name)} />
        </div>
      )}
    </>
  );
};