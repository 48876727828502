import axios, { AxiosError } from "axios";
import { apiData } from ".";
import { popUp } from "../../features/popUp/PopUp";
import { ApiData } from "./renderAndLoadImage";

export class genShortUrlApi extends ApiData {
  public async genShortUrl(info: any): Promise<any> {
    const config = JSON.parse(info);
    try {
      const { data } = await axios.post(
        `${this.baseURL}saveConfiguration`,
        config,
        { headers: { Authorization: `${this.token}` } }
      );
      return data;
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        popUp({ type: "failure", message: err.message });
        if (err.response) {
          return err.response.data;
        } else {
          return err.message;
        }
      }

      return "Error";
    }
  }
}
