import { categoryDataT } from "../../utils/categoriesData";
import s from "./ImageItem.module.scss";
import { FC } from "react";

type ImageItemT = {
  item: categoryDataT;
  onClickFunc: any;
};
export const ImageItem: FC<ImageItemT> = ({ ...props }) => {
  const { item, onClickFunc } = props;

  return (
    <div className={s.wrapper} onClick={onClickFunc}>
      <div className={s.img}>
        <img src={item.imgCover} alt="" />
      </div>
      <div className={s.text}>{item.label}</div>
    </div>
  );
};
