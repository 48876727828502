import { useSelector } from "react-redux";
import { categoryesList, categoryesListT } from "../../utils/constants";
import { ImagePicker } from "../ImagePicker/ImagePicker";
import s from "./ComponentNavigator.module.scss";
import { FC, useEffect, useState } from "react";
import { getCategories, getProducts } from "../../redux/selectors/selectors";
import { SofaIcon } from "../../assets/svg/SofaIcon";
export const ComponentNavigator: FC = () => {
  const [interiorData, setInteriorData] = useState<categoryesListT[]>([]);
  const [environmentData, setEnvironmentData] = useState<categoryesListT[]>([]);
  const categories = useSelector(getCategories());
  const products = useSelector(getProducts());

  useEffect(() => {
    const interData: categoryesListT[] = [];
    const envData: categoryesListT[] = [];
    categoryesList.map((list: categoryesListT) => {
      if (list.type === "interior") {
        interData.push(list);
      } else if (list.type === "environment") {
        envData.push(list);
      }
    });
    categories.map((item: any) => {
      const product: any = [];
      products.map((itemProduct: any) => {
        if (
          itemProduct.categories.find((category: any) => category.name === item)
        ) {
          product.push({
            value: itemProduct.id,
            label: itemProduct.name,
            tags: ["Black color"],
            ...itemProduct,
          });
        }
      });
      //@ts-ignore
      const categoriesData = window.categoriesData;
      const activeIconCategory =
        categoriesData && categoriesData.find((i: any) => i.name === item);

      interData.push({
        type: "interior",
        id: item,
        img: activeIconCategory ? activeIconCategory.image : "",
        label: item,
        data: product,
        search: true,
        sort: true,
        filter: ["Black color", "White color"],
      });
    });
    setInteriorData(interData);
    setEnvironmentData(envData);
  }, [products]);

  return (
    <div className={`${s.wrapper} customScroll`}>
      <div className={s.title}>Environment</div>
      {environmentData.map((item: categoryesListT, i: number) => {
        return <ImagePicker data={item} key={`environment_data_${i}`} />;
      })}
      <div className={s.title}>Interior objects</div>
      {interiorData.map((item: categoryesListT, i: number) => {
        return <ImagePicker data={item} key={`environment_data_${i}`} />;
      })}
    </div>
  );
};
