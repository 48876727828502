export type dimTypeT = "none" | "move" | "rotate" | "snapOn" | "snapOff" | "delete";
export type playerSettingsT = {
  type: dimTypeT;
};
export async function playerSettings({ ...props }: playerSettingsT) {
  const { type } = props;
  //@ts-ignore
  const containerRef = window.containerRef;
  const canvasIframe = containerRef.current.contentWindow;

  if (type === "none") {
    canvasIframe.SetMovementMode(0);
  } else if (type === "move") {
    canvasIframe.SetMovementMode(1);
  } else if (type === "rotate") {
    canvasIframe.SetMovementMode(2);
  } else if (type === "snapOn") {
    canvasIframe.SetAutorotation(true);
  } else if (type === "snapOff") {
    canvasIframe.SetAutorotation(false);
  } else if (type === "delete") {
    canvasIframe.window.DeleteSelectedObject();
  }
}
