import { FC, useEffect, useRef, useState } from "react";
import s from "./PlayCanvasIntegration.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedModel } from "../../../../redux/actions";
import { getSelectedModel } from "../../../../redux/selectors/selectors";
import store from "../../../../redux/store";
import api from "../../../../processes/api";
import { loadConfiguration } from "../../../../utils/functions/playcanvas/loadConfiguration";
import { getConfigInfo } from "../../../../utils/functions/playcanvas/getConfigInfo";
export const PlayCanvasIntegration: FC = () => {
  const containerRef: any = useRef(null);
  const dispatch = useDispatch();
  const [config, setConfig] = useState(null);

  useEffect(() => {
    if (config) {
      console.log("config: ", config);
    }
  }, [config]);
  useEffect(() => {
    async function getSavedConfig() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const configParam: any = urlSearchParams.get("config");
      if (configParam) {
        const configJson = await api.getShortUrlApi.getShortUrl(configParam);
        try {
          await getConfigInfo();
          const dataString = JSON.stringify(configJson);
          await loadConfiguration(dataString);
        } catch {
          console.log("error");
          getSavedConfig();
        }
      }
    }
    if (containerRef) {
      //@ts-ignore
      window.containerRef = containerRef;
      containerRef.current.addEventListener("load", () => {
        setTimeout(() => {
          getSavedConfig();
        }, 1000);
      });
    }
  }, [containerRef]);

  return (
    <div className={s.wrapper}>
      <iframe
        title="scene"
        ref={containerRef}
        src="/playCanvasScene/index.html"
        id="demo"
        width="100%"
        height="100%"
      ></iframe>
    </div>
  );
};
