import { TYPE_REDUCER, categoryId } from "../../utils/constants";
import { dimTypeT } from "../../utils/functions/playcanvas/playerSettings";
import { NameModals } from "../reducers/types";

type changeShowModalT = {
  nameModal: NameModals;
  stateModal: Boolean;
  otherParams?: any;
  [key: string]: any;
};
export const changeShowModal = ({ ...data }: changeShowModalT) => {
  return {
    type: TYPE_REDUCER.SET_MODAL,
    payload: data,
  };
};

type setActiveTabT = {
  activeTab: categoryId | undefined;
};
export const setActiveTab = ({ ...data }: setActiveTabT) => {
  return {
    type: TYPE_REDUCER.SET_ACTIVE_TAB,
    payload: data,
  };
};

type setSelectedModelT = {
  selectedModel?: boolean;
  selectedType?: dimTypeT;
};
export const setSelectedModel = ({ ...data }: setSelectedModelT) => {
  return {
    type: TYPE_REDUCER.SET_SELECTED_MODEL,
    payload: data,
  };
};
type setWsConnectedT = {
  status: boolean;
};
export const setWsConnected = ({ ...data }: setWsConnectedT) => {
  return {
    type: TYPE_REDUCER.SET_WEBSOCKET_CONNECTED,
    payload: data,
  };
};
type setRenderHashT = {
  hash: any;
};
export const setRenderHash = ({ ...data }: setRenderHashT) => {
  return {
    type: TYPE_REDUCER.SET_RENDER_HASH,
    payload: data,
  };
};
type setProductsT = {
  products: any;
};
export const setProducts = ({ ...data }: setProductsT) => {
  return {
    type: TYPE_REDUCER.SET_PRODUCTS,
    payload: data,
  };
};
type setCategoriesT = {
  categories: any;
};
export const setCategories = ({ ...data }: setCategoriesT) => {
  return {
    type: TYPE_REDUCER.SET_CATEGORIES,
    payload: data,
  };
};
type setServerStatusT = {
  serverStatus: any;
};
export const setServerStatus = ({ ...data }: setServerStatusT) => {
  return {
    type: TYPE_REDUCER.SET_SERVER_STATUS,
    payload: data,
  };
};